@font-face {
  font-family: "Union";
  src: url("./fonts/union.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 48px;
}

body {
  background-color: black;
  color: white;
  font-family: "Union", "Helvetica", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 1em;
}

@media (max-width: 1024px) {
  html {
    font-size: 36px;
  }
}

@media (max-width: 800px) {
  html {
    font-size: 32px;
  }

  body {
    margin: 1em 0.5em;
  }
}

@media (max-width: 600px) {
  html {
    font-size: 24px;
  }
}

.AudiateClick,
.AudiateSound {
  font-size: 0.5rem !important;
}
